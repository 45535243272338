@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&family=Noto+Serif+JP&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 変数 ----- */
:root {
  // color
  --color-black: #000000;
  --color-gray-500: #a1a1a1;
  --color-white: #ffffff;
  --color-beige: #dad1cb;
  --color-orange-700: #f85927;
  // z-index
  --zindex-header: 100;
  // media query
  --bp-sm: 440px;
  --bp-md: 768px;
  --bp-lg: 1024px;
  --bp-xl: 1420px;
}

/* font ----- */
.font-12px {
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  line-height: 2;
}
.font-14px {
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  line-height: 1;
}
.font-16px {
  font-size: 1rem;
  letter-spacing: 0.1em;
  line-height: 2;
}
.font-20px {
  font-size: 1.25rem;
  letter-spacing: 0.1em;
  line-height: 1.25;
}
.font-24px {
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  line-height: 1.25;
}
.font-mincho {
  font-family: 'EB Garamond', 'Noto Serif JP', '游明朝', 'Yu Mincho', YuMincho,
    'Hiragino Mincho Pro', serif;
}
.font-link {
  color: var(--color-gray-500);
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.font-bold {
  font-weight: 700;
}

// custom-zoom
.hoverImageZoom {
  overflow: hidden;
  img {
    transition: transform 1.1s ease-out;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

// custom-layout
.contentWrap {
  display: flex;
  gap: 0 30px;
  &.__nomargin {
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 0;
    }
  }
  // bpLg
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 72px 0;
  }
}
.maxWidth {
  max-width: var(--bp-xl);
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.maxWidthSm {
  @media screen and (max-width: 1024px) {
    max-width: var(--bp-sm);
    margin: 0 auto;
  }
}
.maxWidthFull {
  max-width: var(--bp-xl);
  margin-left: auto;
  margin-right: auto;
}
.columnOne {
  width: calc((100% - 60px) / 3);
  // bpLg
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.columnTwo {
  width: calc((100% - 60px) / 3 * 2 + 30px);
  // bpLg
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.visuallyHidden {
  position: fixed !important;
  /* keep it on viewport */
  top: 0px !important;
  left: 0px !important;
  /* give it non-zero size, VoiceOver on Safari requires at least 2 pixels
     before allowing buttons to be activated. */
  width: 4px !important;
  height: 4px !important;
  /* visually hide it with overflow and opacity */
  opacity: 0 !important;
  overflow: hidden !important;
  /* remove any margin or padding */
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  /* ensure no other style sets display to none */
  display: block !important;
  visibility: visible !important;
}
.stickyTitle {
  position: sticky;
  top: 130px;
}
.mt-30 {
  margin-top: 120px;
}
.mt-60 {
  margin-top: 240px;
}
.imageWrap {
  & > span {
    position: static !important;
  }
  img {
    position: static !important;
    width: 100% !important;
    height: auto !important;
  }
}

/* reset ----- */
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'YuGothic', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1,
h2,
h3 {
  font-weight: 200;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

svg {
  overflow: auto;
}

input,
select {
  -webkit-appearance: none;
  appearance: none;
  // border: 1px solid #000;
  border-radius: 0;
}

// @media (prefers-color-scheme: dark) {
//   html {
//     color-scheme: dark;
//   }
//   body {
//     color: white;
//     background: black;
//   }
// }

// メディアクエリ
/* ブレイクポイントの設定 min~max */
$mq-breakpoints-min: (
  'xs': '0px',
  'sm': '820px',
  'md': '1080px',
  'lg': '1336px'
) !default;

$mq-breakpoints-max: (
  'xs': '819.8px',
  'sm': '1079.8px',
  'md': '1335.8px',
  'lg': '99999px'
) !default;

/* mq:それより小さい画面に適応 mq-target:そのメディアクエリのみ */
@mixin mq-target($breakpoint: md) {
  @media screen and (min-width: #{map-get($mq-breakpoints-min, $breakpoint)}) and (max-width: #{map-get($mq-breakpoints-max, $breakpoint)}) {
    @content;
  }
}

@mixin mq($breakpoint: md) {
  @media screen and (max-width: #{map-get($mq-breakpoints-max, $breakpoint)}) {
    @content;
  }
}

// アニメーション
@keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@keyframes slideUp {
  from {
    transform: translate(0, 10px);
  }
  to {
    transform: translate(0, 0);
  }
}

// swiper - - - - - - - - - - - - - - - - - - - - - - - - - - -
.swiper {
  overflow-x: hidden !important;
  padding-bottom: 40px !important;
}
.swiper-button-prev {
  bottom: 0 !important;
  top: inherit !important;
  left: 0 !important;
  z-index: 20 !important;
  &::after {
    font-size: 1rem !important;
    color: #000 !important;
  }
}
.swiper-button-next {
  bottom: 0 !important;
  top: inherit !important;
  right: 0 !important;
  z-index: 20 !important;
  &::after {
    font-size: 1rem !important;
    color: #000 !important;
  }
}
.swiper-pagination {
  bottom: 10px !important;
}
.swiper-pagination-bullet-active {
  background: #000 !important;
}
